import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { path, pathOr, tap } from 'ramda'
import { formatISO } from 'date-fns'
import {
  LESSON_STATUS_BOTH_APPROVED,
  LESSON_STATUS_COACH_APPROVED,
  LESSON_STATUS_COACH_CANCELLED,
  LESSON_STATUS_COMPLETED,
  LESSON_STATUS_DID_NOT_TAKE_PLACE,
  LESSON_STATUS_STUDENT_APPROVED,
  LESSON_STATUS_STUDENT_CANCELLED,
} from '../utils/constants'
import { Users } from '~/types/__generated__/UsersRoute'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { Reviews } from '~/types/__generated__/ReviewsRoute'
import GetStudents = Users.UsersCoachesStudentsRead
import GetStudentLessons = BookingCalendar.BookingCalendarStudentsLessonsList
import GetStudentExams = BookingCalendar.BookingCalendarStudentsExamsList
import GetCompletedCourses = BookingCalendar.BookingCalendarStudentsAttendedCoursesList
import GetReviews = Reviews.ReviewsCoachesReviewsList

const getDefaultStartPageParams = () => ({
  limit: 5,
  start__lte: formatISO(new Date()),
  ordering: '-slot__start',
})

export const state = () => ({
  students: [],
  reviewsResponse: { count: 0, results: [] } as GetReviews.ResponseBody,
  recievedReviewsData: [],
})

export const mutations = mutationTree(state, {
  setStudents(_state, students) {
    _state.students = students
  },
  setReviewsResponse(_state, response) {
    _state.reviewsResponse = response
  },
  setRecievedReviewsData(_state, data) {
    _state.recievedReviewsData = data
  },
})

export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
  nextReviewsPage(_state) {
    return _state.reviewsResponse.next
  },
  reviews(_state) {
    return _state.recievedReviewsData
  },
  reviewsCount(_state) {
    return _state.reviewsResponse.count
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    addLoyaltyBalance({ getters }, params) {
      return this.$axios.post(
        `/api/loyalty/coaches/${getters.userId}/add-package-paid-by-cash/`,
        params
      )
    },
    getStudents({ commit, dispatch }, params) {
      return dispatch('coach_schedule/searchStudents', params, {
        root: true,
      }).then((results) => commit('setStudents', results))
    },
    getStudentsWithFilter({ getters }, status = '') {
      return this.$axios
        .get(`/api/users/coaches/${getters.userId}/students/?status=${status}`)
        .then(pathOr([], ['data']))
    },
    getStudentDetails({ getters }, id): Promise<GetStudents.ResponseBody> {
      return this.$axios
        .get(`/api/users/coaches/${getters.userId}/students/${id}/`)
        .then(pathOr([], ['data']))
    },
    getCompletedLessonsStartPage(
      _,
      studentId
    ): Promise<GetStudentLessons.ResponseBody> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${studentId}/lessons/?status=${LESSON_STATUS_COMPLETED}&status=${LESSON_STATUS_BOTH_APPROVED}`,
          {
            params: getDefaultStartPageParams(),
          }
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getCanceledLessonsStartPage(
      _,
      studentId
    ): Promise<GetStudentLessons.ResponseBody> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${studentId}/lessons/?status=${LESSON_STATUS_COACH_CANCELLED}&status=${LESSON_STATUS_STUDENT_CANCELLED}&status=${LESSON_STATUS_DID_NOT_TAKE_PLACE}&limit=5`
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getExpiredLessonsStartPage(
      _,
      studentId
    ): Promise<GetStudentLessons.ResponseBody> {
      return this.$axios
        .get(`/api/booking-calendar/students/${studentId}/lessons/`, {
          params: {
            status: LESSON_STATUS_COACH_APPROVED,
            ...getDefaultStartPageParams(),
          },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getUpcomingLessonsStartPage(
      _,
      studentId
    ): Promise<GetStudentLessons.ResponseBody> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${studentId}/lessons/?status=${LESSON_STATUS_BOTH_APPROVED}&status=${LESSON_STATUS_COACH_APPROVED}&status=${LESSON_STATUS_STUDENT_APPROVED}`,
          {
            params: {
              limit: 5,
              start__gte: formatISO(new Date()),
              ordering: 'slot__start',
            },
          }
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getNextPageDriveLessons(_, url): Promise<GetStudentLessons.ResponseBody> {
      return this.$axios
        .get(`${url}`)
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    updateStudentDetails({ getters }, { student_id, data }) {
      return this.$axios
        .patch(
          `/api/users/coaches/${getters.userId}/students/${student_id}/`,
          data
        )
        .then(path(['data']))
    },
    getDriveHistoryStudentExams(
      _,
      studentId
    ): Promise<GetStudentExams.ResponseBody['results']> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${studentId}/exams/?limit=500&status=${LESSON_STATUS_BOTH_APPROVED}&status=${LESSON_STATUS_COMPLETED}&status=${LESSON_STATUS_STUDENT_CANCELLED}&status=${LESSON_STATUS_COACH_CANCELLED}&status=${LESSON_STATUS_DID_NOT_TAKE_PLACE}`
        )
        .then(pathOr([], ['data', 'results']))
    },
    getExpiredExamsStartPage(_, studentId) {
      return this.$axios
        .get(`/api/booking-calendar/students/${studentId}/exams/`, {
          params: {
            status: LESSON_STATUS_COACH_APPROVED,
            ...getDefaultStartPageParams(),
          },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getCompletedCoursesStartPage(
      _,
      studentId
    ): Promise<GetCompletedCourses.ResponseBody> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${studentId}/attended-courses/?limit=5`
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getExpiredCoursesStartPage(_, studentId) {
      return this.$axios
        .get(`/api/booking-calendar/students/${studentId}/expired-courses/`, {
          params: getDefaultStartPageParams(),
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getCanceledCoursesStartPage(_, studentId) {
      return this.$axios
        .get(`/api/booking-calendar/students/${studentId}/canceled-courses/`, {
          params: getDefaultStartPageParams(),
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getReviews(
      { commit, getters },
      coachId = getters.userId,
      url = getters.nextReviewsPage
    ): Promise<GetReviews.ResponseBody> {
      return this.$axios
        .get(url || `/api/reviews/coaches/${coachId}/reviews/?limit=5`)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(
          tap((data) => {
            commit('setReviewsResponse', data)
            commit(
              'setRecievedReviewsData',
              url ? getters.reviews.concat(data.results) : data.results
            )
          })
        )
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
